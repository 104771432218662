import { mapHelper } from 'common/utils'

// 数据来源
const sourceType = [
  {
    text: '用户端App',
    value: 1
  },
  {
    text: '微信小程序',
    value: 4
  },
  {
    text: '支付宝小程序',
    value: 3
  },
  {
    text: '物管端APP',
    value: 2
  }
]

const {
  map: sourceTypeMap,
  setOps: sourceTypeOps
} = mapHelper.setMap(sourceType)

// 开门方式
const doorType = [
  {
    text: '扫描道闸二维码',
    value: 1
  },
  {
    text: '蓝牙开门',
    value: 2
  },
  {
    text: '人脸识别',
    value: 5
  }
]

const {
  setOps: doorTypeOps
} = mapHelper.setMap(doorType)

export {
  sourceTypeMap,
  sourceTypeOps,
  doorTypeOps
}
