<!--
 * @Description: 数据中心 > 物业报表 > 人员进出记录 --列表页面
 * @Author: 小广
 * @Date: 2019-07-04 15:42:41
 * @LastEditors: 小广
 * @LastEditTime: 2019-07-08 11:31:12
 -->
<template>
  <div class="PersonPassRecordList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :hasOperateColumn="false"
      :searchBefore="handleSearchBefore"
    >
      <template #searchSlot>
        <v-input label="业主姓名" v-model="searchParams.userName" placeholder="请输入完整姓名"></v-input>
        <v-input label="业主手机" v-model="searchParams.userMoblie" placeholder="请输入完整手机号"></v-input>
        <!-- <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2> -->
        <v-select3  
          ref="select3"
          v-model="searchParams.communityIdList"
          label="所属项目"
          :communityParams="communityParams"
        ></v-select3>
        <v-datepicker
          label="扫码时间"
          type="rangedatetimer"
          :startTime.sync="searchParams.scanTime1"
          :endTime.sync="searchParams.scanTime2">
        </v-datepicker>
        <v-select label="方式" v-model="searchParams.doorType" :options="doorTypeOps"></v-select>
        <v-select label="数据来源" v-model="searchParams.userSource" :options="sourceTypeOps"></v-select>
      </template>
    </list>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import { getListURL, exportListURL } from './api'
import { sourceTypeMap, sourceTypeOps, doorTypeOps } from './map'

export default {
  name: 'PersonPassRecordList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      sourceTypeOps: sourceTypeOps(1),
      doorTypeOps: doorTypeOps(1),
      communityParams: communityParams,
      searchParams: {
        userName: '',
        userMoblie: '',
        communityIdList: '',
        scanTime1: '',
        scanTime2: '',
        doorType: undefined,
        userSource: undefined
      },
      headers: [
        {
          prop: 'community_name',
          label: '所属项目'
        },
        {
          prop: 'regionName',
          label: '所属公司'
        },
        {
          prop: 'user_name',
          label: '业主姓名'
        },
        {
          prop: 'mobile_num',
          label: '业主手机'
        },
        {
          prop: 'address',
          label: '房号'
        },
        {
          prop: 'openType',
          label: '方式'
        },
        {
          prop: 'userSourceStr',
          label: '数据来源',
          formatter (row) {
            return sourceTypeMap[row.userSource]
          }
        },
        {
          prop: 'pass_time',
          label: '扫码时间'
        },
        {
          prop: 'statusStr',
          label: '是否成功',
          formatter (row) {
            return row.status === 1 ? '成功' : '失败'
          }
        }
      ],
      isFirst: true
    }
  },
  methods: {
    async handleSearchBefore(data){
      if(!!data.params.communityIdList){
        return true
      } else {   
        if(this.isFirst) {
          this.isFirst = false
          var result = await this.$refs.select3.remoteMethod("  ",1)
          if(result){
            data.params.communityIdList = result
            return true
          }
        } else {
          this.$message("请选择项目再进行查询！")
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.PersonPassRecordList-wrapper{
  .m-select-container {
    margin-bottom: 20px !important;
  }
}
</style>
